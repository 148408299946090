/* @Fonts
---------------------------------------- */

/* latin */
@font-face {
	font-family: 'Ovo';
	font-style: normal;
	font-weight: 400;
	src: local('Ovo'), url('../fonts/Ovo//Ovo-Regular.ttf') format('ttf'), url(https://fonts.gstatic.com/s/ovo/v10/yYLl0h7WyfzTzI443XaFxQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 100;
	src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'), url('../fonts/Lato/Lato-HairlineItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u-w4BMUTPHjxsIPx-mPCLC79U11vU.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 100;
	src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'), url('../fonts/Lato/Lato-HairlineItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u-w4BMUTPHjxsIPx-oPCLC79U1.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 300;
	src: local('Lato Light Italic'), local('Lato-LightItalic'), url('../fonts/Lato/Lato-LightItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI9w2_FQftx9897sxZ.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 300;
	src: local('Lato Light Italic'), local('Lato-LightItalic'), url('../fonts/Lato/Lato-LightItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI9w2_Gwftx9897g.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	src: local('Lato Italic'), local('Lato-Italic'), url('../fonts/Lato/Lato-Italic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHjxsAUi-qNiXg7eU0.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	src: local('Lato Italic'), local('Lato-Italic'), url('../fonts/Lato/Lato-Italic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHjxsAXC-qNiXg7Q.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 700;
	src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url('../fonts/Lato/Lato-BoldItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI5wq_FQftx9897sxZ.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 700;
	src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url('../fonts/Lato/Lato-BoldItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI5wq_Gwftx9897g.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 900;
	src: local('Lato Black Italic'), local('Lato-BlackItalic'), url('../fonts/Lato/Lato-BlackItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI3wi_FQftx9897sxZ.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 900;
	src: local('Lato Black Italic'), local('Lato-BlackItalic'), url('../fonts/Lato/Lato-BlackItalic.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI3wi_Gwftx9897g.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 100;
	src: local('Lato Hairline'), local('Lato-Hairline'), url('../fonts/Lato/Lato-Hairline.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHh30AUi-qNiXg7eU0.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 100;
	src: local('Lato Hairline'), local('Lato-Hairline'), url('../fonts/Lato/Lato-Hairline.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHh30AXC-qNiXg7Q.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: local('Lato Light'), local('Lato-Light'), url('../fonts/Lato/Lato-Light.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: local('Lato Light'), local('Lato-Light'), url('../fonts/Lato/Lato-Light.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local('Lato Regular'), local('Lato-Regular'), url('../fonts/Lato/Lato-Regular.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local('Lato Regular'), local('Lato-Regular'), url('../fonts/Lato/Lato-Regular.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: local('Lato Bold'), local('Lato-Bold'), url('../fonts/Lato/Lato-Bold.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: local('Lato Bold'), local('Lato-Bold'), url('../fonts/Lato/Lato-Bold.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	src: local('Lato Black'), local('Lato-Black'), url('../fonts/Lato/Lato-Black.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	src: local('Lato Black'), local('Lato-Black'), url('../fonts/Lato/Lato-Black.ttf') format('ttf'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.ttf?ouyjm') format('truetype'), url('../fonts/icomoon.woff?ouyjm') format('woff'), url('../fonts/icomoon.svg?ouyjm#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon', sans-serif !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-envelope::before {
	content: "\e900";
}

.icon-twitter::before {
	content: "\ea96";
}

.icon-github::before {
	content: "\eab0";
}

.icon-linkedin::before {
	content: "\eac9";
}
